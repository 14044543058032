import React from "react";
import { connect } from "react-redux";
import { Link, useLocation, matchPath } from "react-router-dom";
import {
  Container,
  BottomNavigation,
  BottomNavigationAction,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  CssBaseline,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import BusinessIcon from "@material-ui/icons/Business";
import styles from "./PageLayout.module.css";
import { State } from "../../store";
import { selectCompanies } from "@kvittoapp/ducks/src/companies/selectors";
import { Company } from "@kvittoapp/ducks/src/company/models";

type ButtonProps = {
  url: string;
  title: string;
};

type PageLayoutProps = {
  title?: string;
  children: React.ReactNode;
  isAuthenticated: boolean;
  button?: ButtonProps;
  backButton?: { url: string };
  companies: Company[];
};

const PageLayout = ({
  title,
  children,
  isAuthenticated,
  button,
  backButton,
  companies,
}: PageLayoutProps) => {
  const { pathname } = useLocation();
  const navigationItems = [
    "/receipts",
    "/receipts/add",
    "/auth/active-company",
    "/settings",
  ];
  const navigationValue = navigationItems.reduce<string>(
    (value: string, item: string) => {
      const match = matchPath(pathname, item);
      return match ? item : value;
    },
    ""
  );
  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          {backButton && (
            <IconButton component={Link} to={backButton.url} color="inherit">
              <ChevronLeftIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={styles.title}>
            {title}
          </Typography>
          {button && (
            <Button component={Link} to={button.url} color="inherit">
              {button.title}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <div className={styles.container}>{children}</div>
      </Container>
      {isAuthenticated && (
        <>
          <Toolbar />

          <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
            <BottomNavigation showLabels value={navigationValue}>
              <BottomNavigationAction
                component={Link}
                to="/receipts"
                label="Kvitton"
                value="/receipts"
                icon={<ReceiptIcon />}
              />
              <BottomNavigationAction
                component={Link}
                to="/receipts/add"
                label="Registrera"
                value="/receipts/add"
                icon={<AddBoxIcon />}
              />
              {companies.length > 1 && (
                <BottomNavigationAction
                  component={Link}
                  to="/auth/active-company"
                  label="Företag"
                  value="/auth/active-company"
                  icon={<BusinessIcon />}
                />
              )}
              <BottomNavigationAction
                component={Link}
                to="/settings"
                label="Inställningar"
                value="/settings"
                icon={<SettingsIcon />}
              />
            </BottomNavigation>
          </AppBar>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  companies: selectCompanies(state),
  isAuthenticated: !!state.auth.id,
});

export default connect(mapStateToProps)(PageLayout);
