import React, { useState } from "react";
import { PageLayout, PasswordField } from "../../../../components";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import api from "@kvittoapp/api-client/src";
import { State } from "../../../../store";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import styles from "./ChangePassword.module.css";
import Confirmation from "../../../../components/Confirmation/Confirmation";

type FormData = {
  currentPassword: string;
  newPassword: string;
};

interface ChangePasswordProps {
  userId: string | undefined;
}

const ChangePassword = ({ userId }: ChangePasswordProps) => {
  const initialError = undefined;
  const initialFormData = { currentPassword: "", newPassword: "" };
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(initialError);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(initialError);
    setSubmitting(true);
    setSuccess(false);
    try {
      await api.updateUser(userId!, formData);
      setFormData(initialFormData);
      setSuccess(true);
    } catch (e) {
      setError("Något blev fel. Försök igen.");
    } finally {
      setSubmitting(false);
    }
  };

  const onInputChange = (name: keyof FormData) => (password: string) => {
    setFormData({
      ...formData,
      [name]: password,
    });
  };

  if (success) {
    return (
      <Confirmation
        title="Lösenordet är ändrat"
        message="Ditt lösenord är ändrat"
      />
    );
  }

  return (
    <PageLayout title="Ändra ditt lösenord">
      <form onSubmit={onSubmit}>
        <div className={styles.formFields}>
          <PasswordField
            id="current-password"
            label="Ditt nuvarande lösenord"
            value={formData.currentPassword}
            onChange={onInputChange("currentPassword")}
            disabled={submitting}
            required
          />
          <PasswordField
            id="new-password"
            label="Nytt lösenord"
            value={formData.newPassword}
            onChange={onInputChange("newPassword")}
            disabled={submitting}
            required
          />
        </div>
        {error && (
          <div className={styles.errorMessage}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              component={Link}
              to="/settings"
              variant="contained"
              color="default"
              fullWidth
              disabled={submitting}
            >
              Avbryt
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              Ändra
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

const mapStateToProps = (state: State) => ({
  userId: state.auth.id,
});

export default connect(mapStateToProps)(ChangePassword);
