import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import * as authActions from "@kvittoapp/ducks/src/auth";
import api from "@kvittoapp/api-client/src";
import { PageLayout } from "../../../components";
import { Button, Grid } from "@material-ui/core";

const mapDispatchToProps = {
  remove: () => authActions.remove(),
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface LogoutProps extends PropsFromRedux {}

const Logout = ({ remove }: LogoutProps) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await api.deleteAuth();
      if (result.status === 200) {
        setLoggedOut(true);
        remove();
      }
    } catch (e) {
      console.error(e);
    }
  };
  if (loggedOut) {
    navigate("/");
  }
  return (
    <PageLayout title="Logga ut">
      <p>Loggar du ut måste du logga in igen</p>
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              component={Link}
              to="/settings"
              variant="contained"
              color="default"
              fullWidth
            >
              Avbryt
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
            >
              Logga ut
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

export default connector(Logout);
