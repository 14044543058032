import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import currencyJs from "currency.js";
import * as voucherActions from "@kvittoapp/ducks/src/vouchers";
import { Voucher } from "@kvittoapp/ducks/src/vouchers/models";
import { selectVouchersByCompanyId } from "@kvittoapp/ducks/src/vouchers/selectors";
import { PageLayout } from "../../components";
import { TableContainer, Table, TableBody } from "@material-ui/core";
import { State } from "../../store";
import { MonthlySummaries } from "./types";
import { SummaryRow, Row } from "./components";
import { selectActiveCompany } from "../../ducks/active-company/selectors";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    vouchers: selectVouchersByCompanyId(companyId)(state),
    companyId,
    userId: state.auth.id,
  };
};

const mapDispatchToProps = {
  get: voucherActions.getVouchers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ReceiptsProps extends PropsFromRedux {}

const normalizedMonthByTransactionDate = (transactionDate: string) => {
  const date = new Date(transactionDate);
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-01`;
};

const summarizeMonths = (vouchers: Voucher[]) =>
  vouchers.reduce<MonthlySummaries>(
    (summaries: MonthlySummaries, voucher: Voucher) => {
      const normalizedDate = normalizedMonthByTransactionDate(
        voucher.transactionDate
      );
      const summary = !summaries[normalizedDate]
        ? {
            total: currencyJs(0),
            date: normalizedDate,
          }
        : summaries[normalizedDate];
      return {
        ...summaries,
        [normalizedDate]: {
          ...summary,
          total: summary.total.add(voucher.total),
        },
      };
    },
    {}
  );

const Receipts = ({ get, vouchers, companyId, userId }: ReceiptsProps) => {
  useEffect(() => {
    get({ companyId, userId });
  }, [companyId, userId, get]);

  const sortedVouchers = [...vouchers].sort(
    (a: Voucher, b: Voucher) =>
      new Date(b.transactionDate).getTime() -
      new Date(a.transactionDate).getTime()
  );
  const summarizedMonths = summarizeMonths(vouchers);

  return (
    <PageLayout title="Mina registrerade kvitton">
      <TableContainer>
        <Table size="small">
          <TableBody>
            {sortedVouchers.map((voucher: Voucher, index: number) => {
              const currentMonth = normalizedMonthByTransactionDate(
                voucher.transactionDate
              );
              const previousMonth =
                index > 0
                  ? normalizedMonthByTransactionDate(
                      sortedVouchers[index - 1].transactionDate
                    )
                  : "";
              if (currentMonth !== previousMonth) {
                const summary = summarizedMonths[currentMonth];
                return (
                  <React.Fragment key={index}>
                    <SummaryRow summary={summary} key={currentMonth} />
                    <Row voucher={voucher} key={voucher.id} />
                  </React.Fragment>
                );
              }
              return <Row voucher={voucher} key={voucher.id} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PageLayout>
  );
};

export default connector(Receipts);
