import { Navigate, Outlet, RouteProps } from "react-router";
import { useSelector } from "react-redux";
import { State } from "../../store";
import { selectActiveCompany } from "../../ducks/active-company/selectors";
import { selectUserRoleByCompanyId } from "@kvittoapp/ducks/src/users/selectors";
import { ActiveCompany } from "../../pages/auth";

export type PrivateRouteProps = RouteProps & {
  requiredRole?: string;
};
const PrivateRoute = (props: PrivateRouteProps) => {
  const isAuthenticated = useSelector((state: State) => !!state.auth.id);
  const companyId = useSelector(selectActiveCompany);
  const userId = useSelector((state: State) =>
    state.auth.id ? state.auth.id : ""
  );
  const userRole = useSelector(
    selectUserRoleByCompanyId({ id: userId, companyId })
  );

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }
  if (props.requiredRole && userRole !== props.requiredRole) {
    return <div />;
  }

  if (companyId.length === 0) {
    return <ActiveCompany redirectPath="/auth/active-company" />;
  }
  return <Outlet />;
};

export default PrivateRoute;
