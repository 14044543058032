import React, { useState, ChangeEvent, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../store";
import api from "@kvittoapp/api-client/src";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { PageLayout, Select } from "../../components";
import styles from "./Receipt.module.css";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Link,
} from "react-router-dom";
import * as costTypesActions from "@kvittoapp/ducks/src/costTypes";
import * as costCentersActions from "@kvittoapp/ducks/src/costCenters";
import * as paymentTypesActions from "@kvittoapp/ducks/src/paymentTypes";
import * as userSettingsActions from "@kvittoapp/ducks/src/userSettings";
import * as projectsActions from "@kvittoapp/ducks/src/projects";
import FileUploadInput from "./FileUploadInput";
import Confirmation from "../../components/Confirmation/Confirmation";
import { createSelector } from "@reduxjs/toolkit";
import {
  selectEnabledCostTypes,
  selectDefaultCostType,
} from "@kvittoapp/ducks/src/costTypes/selectors";
import {
  selectEnabledPaymentTypes,
  selectDefaultPaymentType,
} from "@kvittoapp/ducks/src/paymentTypes/selectors";
import {
  selectDefaultCostCenter,
  selectEnabledCostCentersByCompanyId,
} from "@kvittoapp/ducks/src/costCenters/selectors";
import { selectOnGoingProjectsByCompanyId } from "@kvittoapp/ducks/src/projects/selectors";
import { selectActiveCompany } from "../../ducks/active-company/selectors";
import { selectCompanyById } from "@kvittoapp/ducks/src/companies/selectors";
import { File } from "@kvittoapp/ducks/src/vouchers/models";
import axios from "axios";

const selectInitialCostType = (companyId: string) =>
  createSelector(
    selectDefaultCostType(companyId),
    selectEnabledCostTypes(companyId),
    (defaultCostType, enabledCostTypes) => {
      if (defaultCostType && defaultCostType.length > 0) {
        return defaultCostType;
      }
      if (enabledCostTypes.length === 1) {
        return enabledCostTypes[0].id;
      }
      return "";
    }
  );
const selectInitialPaymentType = (companyId: string) =>
  createSelector(
    selectDefaultPaymentType(companyId),
    selectEnabledPaymentTypes(companyId),
    (defaultPaymentType, enabledPaymentTypes) => {
      if (defaultPaymentType && defaultPaymentType.length > 0) {
        return defaultPaymentType;
      }
      if (enabledPaymentTypes.length === 1) {
        return enabledPaymentTypes[0].id;
      }
      return "";
    }
  );

const selectInitialCostCenter = (companyId: string) =>
  createSelector(
    selectDefaultCostCenter(companyId),
    selectEnabledCostCentersByCompanyId(companyId),
    (defaultCostCenter, enabledCostCenters) => {
      if (defaultCostCenter && defaultCostCenter.length > 0) {
        return defaultCostCenter;
      }
      if (enabledCostCenters.length === 1) {
        return enabledCostCenters[0].id;
      }
      return "";
    }
  );
const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    costCenters: selectEnabledCostCentersByCompanyId(companyId)(state),
    projects: selectOnGoingProjectsByCompanyId(companyId)(state),
    paymentTypes: selectEnabledPaymentTypes(companyId)(state),
    costTypes: selectEnabledCostTypes(companyId)(state),
    companyId,
    userId: state.auth.id ?? "",
    selectedVoucherSeries: state.company.voucherSeries,
    initialPaymentType: selectInitialPaymentType(companyId)(state),
    initialCostType: selectInitialCostType(companyId)(state),
    initialCostCenter: selectInitialCostCenter(companyId)(state),
    company: selectCompanyById(companyId)(state),
  };
};

const mapDispatchToProps = {
  getCostCenters: costCentersActions.getCostCenters,
  getProjects: projectsActions.getProjects,
  getCostTypes: costTypesActions.getCostTypes,
  getPaymentTypes: paymentTypesActions.getPaymentTypes,
  getUserSettings: userSettingsActions.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface AddReceiptProps extends PropsFromRedux {
  companyId: string;
  userId: string;
}

const AddReceipt = ({
  companyId,
  company,
  userId,
  paymentTypes,
  costCenters,
  costTypes,
  projects,
  initialCostType,
  initialPaymentType,
  initialCostCenter,
  getCostCenters,
  getCostTypes,
  getPaymentTypes,
  getUserSettings,
  getProjects,
}: AddReceiptProps) => {
  type FormData = {
    vat: string;
    total: string;
    costType: string;
    paymentType: string;
    description: string;
    transactionDate: string;
    costCenter: string;
    file: string;
    project: { label: string; value: string };
    isReturn: boolean;
  };
  type ResponseError = {
    ErrorInformation?: {
      error: string;
      message: string;
      code: number;
    };
  };

  const initialFormData: FormData = {
    vat: "",
    total: "",
    costType: initialCostType,
    paymentType: initialPaymentType,
    description: "",
    transactionDate: format(new Date(), "yyyy-MM-dd"),
    costCenter: initialCostCenter,
    file: "",
    project: {
      value: "",
      label: "",
    },
    isReturn: false,
  };
  const storedUploadedFile = window.localStorage?.getItem("uploadedFile");
  const initialError: ResponseError = {};
  const [formData, setFormData] = useState(initialFormData);
  const [total, setTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [error, setError] = useState<ResponseError>(initialError);
  const [submitting, setSubmitting] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [file, setFile] = useState<File | undefined>(
    storedUploadedFile ? JSON.parse(storedUploadedFile) : undefined
  );

  const setUploadedFile = (uploadedFile: File | undefined) => {
    setFile(uploadedFile);
    if (uploadedFile) {
      window.localStorage?.setItem(
        "uploadedFile",
        JSON.stringify(uploadedFile)
      );
    } else {
      window.localStorage?.removeItem("uploadedFile");
    }
  };

  const vatFromTotal = (total: number, vatPercentage: number) =>
    Math.round(total * 100 - (total * 100) / (1 + vatPercentage)) / 100;

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      if (vat > total) {
        return setError({
          ErrorInformation: {
            error: "Totalt belopp och moms felaktigt angiven",
            message:
              "Angiven moms är större än angivet totalt belopp. Korrigera och skicka igen, eller kontakta ekonomiansvarig.",
            code: 0,
          },
        });
      }
      if (total === 0 && vat > 0) {
        return setError({
          ErrorInformation: {
            error: "Totalt belopp saknas",
            message:
              "Totalt belopp är inte angivet. Du har nog skrivit fel. Kontrollera totalt belopp och moms.",
            code: 0,
          },
        });
      }
      if (!file) {
        return setError({
          ErrorInformation: {
            error: "",
            message: "Inget fotografi av kvitto angivet",
            code: 0,
          },
        });
      }
      await api.createReceipt(companyId!, userId!, {
        vat: vat,
        total: total,
        costType: formData.costType,
        paymentType: formData.paymentType,
        description: formData.description,
        transactionDate: formData.transactionDate,
        costCenter: formData.costCenter,
        fileId: file.id,
        project: formData.project.value,
        isReturn: formData.isReturn,
      });
      setRegisterSuccess(true);
      setUploadedFile(undefined);
    } catch (e) {
      setRegisterSuccess(false);
      if (axios.isAxiosError(e) && e.response) {
        setError(e.response.data as ResponseError);
      } else {
        setError({
          ErrorInformation: {
            error: "Okänt fel",
            message: "Ett okänt fel inträffade.",
            code: 0,
          },
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onFormDataChange =
    (name: keyof FormData) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [name]: e.target.value });
    };

  const onSelectChange = (name: keyof FormData) => (value: string) => {
    setFormData({ ...formData, [name]: value });
  };
  const onAutocompleteChange =
    (name: keyof FormData) => (_: ChangeEvent<{}>, value: unknown) =>
      setFormData({ ...formData, [name]: value });

  const onCostTypeChange = (costTypeId: string) => {
    const costType =
      costTypes.find((costType) => costType.id === costTypeId) || costTypes[0];
    const vatPercentage = parseInt(costType.vat, 10) / 100;
    const vat = vatFromTotal(total, vatPercentage);
    setVat(vat);
    setFormData({
      ...formData,
      costType: costTypeId,
      vat: vat.toString(),
    });
  };

  const onDateChange =
    (name: keyof FormData) => (date: MaterialUiPickersDate) => {
      const dateToFormat = date || new Date();
      const formattedDate = format(dateToFormat, "yyyy-MM-dd");
      setFormData({ ...formData, [name]: formattedDate });
    };

  const onTotalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawTotal = e.target.value;
    if (rawTotal.length === 0) {
      setTotal(0);
      setVat(0);
      return setFormData({
        ...formData,
        total: "",
        vat: "",
      });
    }
    const total = parseFloat(rawTotal);
    if (formData.costType) {
      const costType =
        costTypes.find((costType) => costType.id === formData.costType) ||
        costTypes[0];
      const vatPercentage = parseFloat(costType.vat) / 100;
      const vat = vatFromTotal(total, vatPercentage);
      setTotal(total);
      setVat(vat);
      return setFormData({
        ...formData,
        total: rawTotal,
        vat: vat.toString(),
      });
    }
    return setFormData({
      ...formData,
      total: rawTotal,
    });
  };

  const onVatChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawVat = e.target.value;
    setVat(parseFloat(e.target.value));
    return setFormData({
      ...formData,
      vat: rawVat,
    });
  };

  const onIsReturnChange = (_: ChangeEvent<{}>, checked: boolean) => {
    return setFormData({
      ...formData,
      isReturn: checked,
    });
  };

  const resetForm = () => {
    setRegisterSuccess(false);
    setUploadedFile(undefined);
    setFormData(initialFormData);
    setVat(0);
    setTotal(0);
  };

  const cancel = () => {
    resetForm();
    if (file) {
      api.deleteFile(companyId, file.id);
    }
  };

  useEffect(() => {
    getCostTypes({ companyId });
    getPaymentTypes({ companyId });
    getCostCenters({ companyId });
    getUserSettings({ companyId, userId });
    getProjects({ companyId });
  }, [
    companyId,
    userId,
    getCostTypes,
    getPaymentTypes,
    getCostCenters,
    getUserSettings,
    getProjects,
  ]);
  useEffect(() => {
    setFormData((f) => ({ ...f, costCenter: initialCostCenter }));
  }, [initialCostCenter]);
  useEffect(() => {
    setFormData((f) => ({ ...f, costType: initialCostType }));
  }, [initialCostType]);
  useEffect(() => {
    setFormData((f) => ({ ...f, paymentType: initialPaymentType }));
  }, [initialPaymentType]);

  if (registerSuccess) {
    const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to">>(
      (props, ref) => <RouterLink ref={ref} to="/receipts" {...props} />
    );
    return (
      <Confirmation
        title="Kvitto registrerat"
        message="Ditt kvitto är registrerat"
      >
        <Box marginBottom={3}>
          <Button variant="contained" color="primary" onClick={resetForm}>
            Registrera ett till kvitto
          </Button>
        </Box>
        <Box>
          <Button variant="outlined" component={LinkBehavior}>
            Se kvitton
          </Button>
        </Box>
      </Confirmation>
    );
  }

  return (
    <PageLayout title="Registrera kvitto">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form onSubmit={onSubmit}>
          <Box marginBottom={2}>
            <FileUploadInput
              companyId={companyId}
              onChange={setUploadedFile}
              uploadedFile={file}
            />
          </Box>
          {costCenters.length > 0 && company?.displayCostCenterInput && (
            <Select
              key={`costCenter-${formData.costCenter}`}
              id="costCenter"
              name="Kostnadsställe"
              value={formData.costCenter}
              onChange={onSelectChange("costCenter")}
              options={costCenters.map(({ id, description }) => ({
                value: id,
                description,
              }))}
            />
          )}
          {projects.length > 0 && (
            <FormControl fullWidth margin="normal">
              <Autocomplete
                disablePortal
                options={projects.map<{ value: string; label: string }>(
                  ({ projectNumber, description }) => ({
                    value: projectNumber,
                    label: `${projectNumber}: ${description}`,
                  })
                )}
                value={formData.project}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                onChange={onAutocompleteChange("project")}
                key={`project-${formData.project}`}
                id="project"
                renderInput={(params) => (
                  <TextField {...params} label="Projekt" />
                )}
              />
            </FormControl>
          )}
          <Select
            key={`costType-${formData.costType}`}
            id="costType"
            name="Kostnadstyp"
            value={formData.costType}
            onChange={onCostTypeChange}
            required
            options={costTypes.map(({ id, description }) => ({
              value: id,
              description,
            }))}
          />
          <Select
            key={`paymentType-${formData.paymentType}`}
            id="paymentType"
            name="Betalsätt"
            value={formData.paymentType}
            onChange={onSelectChange("paymentType")}
            required
            options={paymentTypes.map(({ id, description }) => ({
              value: id,
              description,
            }))}
          />
          <DatePicker
            format="yyyy-MM-dd"
            margin="normal"
            label="Transaktionsdatum"
            value={formData.transactionDate}
            required
            fullWidth
            onChange={onDateChange("transactionDate")}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Beskrivning"
            name="description"
            value={formData.description}
            onChange={onFormDataChange("description")}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="Totalt belopp"
                name="total"
                value={formData.total}
                onChange={onTotalChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="Moms"
                name="vat"
                value={formData.vat}
                onChange={onVatChange}
              />
            </Grid>
          </Grid>
          <Box paddingY={1}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked color="primary" />}
                label="Det här är en retur"
                checked={formData.isReturn}
                onChange={onIsReturnChange}
              />
            </FormGroup>
          </Box>
          {error.ErrorInformation && (
            <div className={styles.errorMessage}>
              <Alert severity="error">{error.ErrorInformation.message}</Alert>
            </div>
          )}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/receipts"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
                onClick={cancel}
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={submitting}
              >
                {submitting ? "Registrerar..." : "Registrera"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </PageLayout>
  );
};

export default connector(AddReceipt);
