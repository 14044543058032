import React, { ChangeEvent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as usersActions from "@kvittoapp/ducks/src/users";
import { User } from "@kvittoapp/ducks/src/users/models";
import { selectUsersByCompanyId } from "@kvittoapp/ducks/src/users/selectors";
import { State } from "../../../store";
import { PageLayout } from "../../../components";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import styles from "./UsersSettings.module.css";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    users: selectUsersByCompanyId(companyId)(state),
    companyId,
  };
};

const mapDispatchToProps = {
  get: (companyId: string) => usersActions.getCompanyUsers({ companyId }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface UsersSettingsProps extends PropsFromRedux {
  users: User[];
  companyId: string;
}

const UsersSettings = ({ get, users, companyId }: UsersSettingsProps) => {
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(true);
  useEffect(() => {
    get(companyId!);
  }, [get, companyId]);
  const onShowOnlyEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowOnlyEnabled(e.target.checked);
  };
  return (
    <PageLayout
      title="Användare"
      button={{ title: "Lägg till", url: "/settings/company/users/add" }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={showOnlyEnabled}
            onChange={onShowOnlyEnabledChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Visa bara aktiva"
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>E-postadress</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter(
                (user) =>
                  (showOnlyEnabled &&
                    user.UserCompanyAccesses.find(
                      (a) => a.companyId === companyId && a.enabled
                    )) ||
                  !showOnlyEnabled
              )
              .sort((a, b) => (a.email > b.email ? 1 : -1))
              .map((user: User) => (
                <Row user={user} key={user.id} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageLayout>
  );
};
interface RowProps {
  user: User;
}

const Row = ({ user }: RowProps) => {
  const [redirect, setRedirect] = useState<undefined | string>(undefined);
  const navigate = useNavigate();
  if (redirect) {
    navigate(redirect);
  }

  return (
    <TableRow
      onClick={() => setRedirect(`/settings/company/users/${user.id}/edit`)}
      className={styles.row}
    >
      <TableCell>{user.email}</TableCell>
      <TableCell padding="none">
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  );
};

export default connector(UsersSettings);
