import React, { useState } from "react";
import { Voucher } from "@kvittoapp/ducks/src/vouchers/models";
import { TableRow, TableCell } from "@material-ui/core";
import { currency } from "../../../utils";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styles from "../Receipts.module.css";
import { useNavigate } from "react-router-dom";

interface RowProps {
  voucher: Voucher;
}

const Row = ({ voucher }: RowProps) => {
  const [redirect, setRedirect] = useState<undefined | string>(undefined);
  const formattedTotal = currency.SEK(voucher.total);
  const navigate = useNavigate();

  if (redirect) {
    navigate(redirect);
  }

  return (
    <TableRow
      className={styles.row}
      onClick={() => setRedirect(`/receipts/${voucher.id}`)}
    >
      <TableCell>{voucher.description}</TableCell>
      <TableCell className={styles.dateCell} align="right">
        {voucher.transactionDate}
        <br />
        {formattedTotal}
      </TableCell>
      <TableCell padding="none">
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  );
};

export default Row;
