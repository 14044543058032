import React, { ChangeEvent, useState } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import api from "@kvittoapp/api-client/src";
import { TextField, Button, Box } from "@material-ui/core";
import { Auth } from "@kvittoapp/ducks/src/auth/models";
import * as authActions from "@kvittoapp/ducks/src/auth";
import { PageLayout } from "../../components";
import ReActivate from "./ReActivate";
import Confirmation from "../../components/Confirmation/Confirmation";
import axios from "axios";

const mapDispatchToProps = {
  add: (auth: Auth) => authActions.add(auth),
};

const buildAuthorizationUrl = () => {
  const basePath = "https://apps.fortnox.se/oauth-v1/auth";
  const clientId = process.env.REACT_APP_FORTNOX_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_FORTNOX_OAUTH2_REDIRECT_URI ?? "";
  const scope = [
    "bookkeeping",
    "inbox",
    "costcenter",
    "companyinformation",
    "connectfile",
    "project",
    "settings",
  ];
  const encodedRedirectUri = encodeURIComponent(redirectUri);
  const encodedScope = encodeURIComponent(scope.join(" "));
  return `${basePath}?client_id=${clientId}&redirect_uri=${encodedRedirectUri}&scope=${encodedScope}&state=somestate123&access_type=offline&response_type=code&account_type=service`;
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SignupProps extends PropsFromRedux {
  code?: string;
}

const Signup = ({ add, code }: SignupProps) => {
  const [email, setEmail] = useState("");
  const [accountant, setAccountant] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginFail, setLoginFail] = useState(false);

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onAccountantChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccountant(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!code) {
      throw new Error("No authorization code");
    }
    try {
      await api.registerCompany(email, password, accountant, code);
      const result = await api.createAuth(email, password);
      const auth = await api.getAuth();
      if (result.status === 200) {
        add(auth.data);
        setLoggedIn(true);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 403) {
        setLoginFail(true);
      }
    }
  };
  if (loginFail) {
    const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to">>(
      (props, ref) => <RouterLink ref={ref} to="/" {...props} />
    );
    return (
      <Confirmation
        title="Företag registrerat"
        message="Välkommen till Enkla Kvitton!"
      >
        <Box marginBottom={3}>
          Det verkar som att en användare redan är registrerad med adressen du
          angav. Logga in med den för att komma igång med ditt nya företag.
        </Box>
        <Button component={LinkBehavior}>Logga in</Button>
      </Confirmation>
    );
  }
  if (loggedIn) {
    const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, "to">>(
      (props, ref) => <RouterLink ref={ref} to="/settings" {...props} />
    );
    return (
      <Confirmation
        title="Företag registrerat"
        message="Välkommen till Enkla Kvitton!"
      >
        <Box marginBottom={3}>
          För att komma igång behöver du lägga till betalsätt och kostnadstyper.
          Det gör du bland inställningarna.
        </Box>
        <Button component={LinkBehavior}>Gå till inställningar</Button>
      </Confirmation>
    );
  }
  if (!code) {
    return (
      <PageLayout title="Registrera ditt företag">
        <Box marginBottom={5}>
          <p>Välkommen till Enkla Kvitton!</p>
          <p>
            För att komma igång måste du börja med att godkänna att vi får
            integrera mot Fortnox. Klicka på knappen nedan för att logga in i
            Fortnox och godkänna Enkla Kvitton.
          </p>
          <p>
            När du är klar kommer du tillbaka till Enkla Kvittons
            registreringssida, där du får fylla i användaruppgifter.
          </p>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => (window.location.href = buildAuthorizationUrl())}
          >
            Börja här
          </Button>
        </Box>
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Registrera ditt företag">
      <Box marginBottom={5}>
        <form onSubmit={onSubmit}>
          <p>
            Fyll i dina uppgifter nedan för att registrera företaget för Enkla
            Kvitton. Vi hämtar sedan den informationen vi behöver från Fortnox.
          </p>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Din e-postadress"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={onEmailChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Lösenord"
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={onPasswordChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="accountant"
            label="E-postadress för månadsrapporter"
            name="accountant"
            value={accountant}
            onChange={onAccountantChange}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Registrera
          </Button>
        </form>
      </Box>
      <ReActivate authorizationCode={code} />
    </PageLayout>
  );
};

export default connector(Signup);
