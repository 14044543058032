import * as ducksReducers from "@kvittoapp/ducks/src";
import { State as DucksState } from "@kvittoapp/ducks/src";
import * as appReducers from "./ducks/";
import { State as AppState } from "./ducks";
import { configureStore } from "@reduxjs/toolkit";

type State = DucksState & AppState;

const reducers = {
  ...ducksReducers,
  ...appReducers,
};

const state: State = {
  costTypes: {},
  paymentTypes: {},
  auth: {},
  users: [],
  vouchers: {},
  voucherSeries: {},
  company: {
    id: "",
    name: "",
    voucherSeries: "",
    accountant: "",
    organizationNumber: "",
    creationDate: "",
    updatedOn: "",
    displayCostCenterInput: true,
    enabled: true,
    latestActivityDate: "",
  },
  costCenters: {},
  userSettings: [],
  companies: [],
  activeCompany: "",
  projects: {},
};

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
  preloadedState: state,
});

export type { State };
export default store;
