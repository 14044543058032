import React, { useState, ChangeEvent, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../../store";
import { PageLayout } from "../../../components";
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import styles from "./Add.module.css";
import * as paymentTypesActions from "@kvittoapp/ducks/src/paymentTypes";
import { selectPaymentTypesByCompanyId } from "@kvittoapp/ducks/src/paymentTypes/selectors";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import axios from "axios";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    paymentTypes: selectPaymentTypesByCompanyId(companyId)(state),
    companyId,
  };
};

const mapDispatchToProps = {
  update: paymentTypesActions.updatePaymentType,
  get: paymentTypesActions.getPaymentTypes,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type FormData = {
  accountNumber: string;
  description: string;
  enabled: boolean;
};

type ErrorResponse = {
  message: string;
};

interface FormPaymentTypeProps extends PropsFromRedux {}

const FormPaymentType = ({
  companyId,
  update,
  get,
  paymentTypes,
}: FormPaymentTypeProps) => {
  const initialFormData: FormData = {
    accountNumber: "",
    description: "",
    enabled: true,
  };
  const { paymentTypeId } = useParams();
  const initialError: ErrorResponse | undefined = undefined;
  const [formData, setFormData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState<ErrorResponse | undefined>(initialError);
  const navigate = useNavigate();

  useEffect(() => {
    get({ companyId });
  }, [companyId, get]);

  useEffect(() => {
    const paymentType = paymentTypes.find(
      (paymentType) => paymentType.id === (paymentTypeId as string)
    );
    if (paymentType) {
      setFormData({
        accountNumber: paymentType.accountNumber.toString(),
        description: paymentType.description,
        enabled: paymentType.enabled,
      });
    }
  }, [paymentTypeId, paymentTypes]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError(initialError);

    try {
      update({
        companyId,
        paymentTypeId: paymentTypeId as string,
        params: {
          accountNumber: formData.accountNumber,
          description: formData.description,
          enabled: formData.enabled,
        },
      });
      setFormData(initialFormData);
      setSubmitting(false);
      setSubmitSuccess(true);
    } catch (e) {
      setSubmitting(false);
      setSubmitSuccess(false);
      if (axios.isAxiosError(e) && e.response) {
        setError(e.response.data as ErrorResponse);
      }
    }
  };
  const onFormDataChange =
    (name: keyof FormData) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [name]: e.target.value });
    };

  const onEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, enabled: e.target.checked });
  };

  const title = "Ändra betalsätt";
  const submitLabel = "Ändra";

  if (submitSuccess) {
    navigate(`/settings/company/payment-types/`);
  }
  return (
    <PageLayout title={title}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              autoFocus
              type="text"
              label="Konto"
              name="account"
              disabled={true}
              value={formData.accountNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="text"
              label="Beskrivning"
              name="total"
              value={formData.description}
              onChange={onFormDataChange("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.enabled}
                  color="primary"
                  onChange={onEnabledChange}
                />
              }
              label="Aktiv"
            />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <div className={styles.errorMessage}>
                <Alert severity="error">{error.message}</Alert>
              </div>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/settings/company/payment-types"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                className={styles.submitButton}
                fullWidth
              >
                {submitLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

export default connector(FormPaymentType) as any;
