import React, { useEffect, useState, ChangeEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import * as costTypeActions from "@kvittoapp/ducks/src/costTypes";
import { CostType } from "@kvittoapp/ducks/src/costTypes/models";
import { State } from "../../../store";
import { PageLayout } from "../../../components";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styles from "./CostTypesSettings.module.css";
import { selectCostTypesByCompanyId } from "@kvittoapp/ducks/src/costTypes/selectors";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state: State) => {
  const companyId = selectActiveCompany(state);
  return {
    costTypes: selectCostTypesByCompanyId(companyId)(state),
    companyId,
  };
};

const mapDispatchToProps = {
  get: costTypeActions.getCostTypes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface CostTypesSettingsProps extends PropsFromRedux {
  costTypes: CostType[];
}

const CostTypesSettings = ({
  costTypes,
  companyId,
  get,
}: CostTypesSettingsProps) => {
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(true);
  useEffect(() => {
    get({ companyId });
  }, [companyId, get]);
  const onShowOnlyEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowOnlyEnabled(e.target.checked);
  };
  return (
    <PageLayout
      title="Kostnadstyper"
      button={{ title: "Lägg till", url: "/settings/company/cost-types/add" }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={showOnlyEnabled}
            onChange={onShowOnlyEnabledChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Visa bara aktiva"
      />
      <TableContainer>
        <Table size="small">
          <TableBody>
            {[...costTypes]
              .filter(
                (costType) =>
                  (showOnlyEnabled && costType.enabled) || !showOnlyEnabled
              )
              .sort((a, b) => (a.description > b.description ? 1 : -1))
              .map((costType: CostType) => (
                <Row costType={costType} key={costType.id} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageLayout>
  );
};
interface RowProps {
  costType: CostType;
}

const Row = ({ costType }: RowProps) => {
  const [redirect, setRedirect] = useState<undefined | string>(undefined);
  const navigate = useNavigate();

  if (redirect) {
    navigate(redirect);
  }

  return (
    <TableRow
      onClick={() =>
        setRedirect(`/settings/company/cost-types/${costType.id}/edit`)
      }
      className={styles.row}
    >
      <TableCell>{costType.description}</TableCell>
      <TableCell align="right">
        {costType.accountNumber}
        <br />
        {costType.vat}%
      </TableCell>
      <TableCell padding="none">
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  );
};

export default connector(CostTypesSettings);
