import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, Box } from "@material-ui/core";
import { PageLayout, PasswordField } from "../../components";
import { Alert } from "@material-ui/lab";
import api from "@kvittoapp/api-client/src";
import { getErrorMessage } from "../../utils";
import Confirmation from "../../components/Confirmation/Confirmation";
import axios from "axios";

interface ResetPasswordFormProps {
  token: string;
}

const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await api.resetPassword(token, password);
      setSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        switch (e.code) {
          case "404":
            setErrorMessage("RESET_PASSWORD_TOKEN_NOT_FOUND");
            break;
          case "403":
            setErrorMessage("RESET_PASSWORD_TOKEN_EXPIRED");
            break;
          case "500":
            setErrorMessage("USER_NOT_FOUND");
            break;
          default:
            setErrorMessage("UNKNOWN_ERROR");
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (success) {
    return (
      <Confirmation
        title="Lösenord återställt"
        message="Ditt lösenord är återställt."
      >
        <Box alignContent="center">
          <Button component={Link} to="/login" variant="outlined">
            Logga in
          </Button>
        </Box>
      </Confirmation>
    );
  }

  return (
    <PageLayout backButton={{ url: "/login" }} title="Återställa lösenord">
      <p>Ange ditt nya lösenord</p>
      <form onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Box marginBottom={1}>
              <PasswordField
                id="password"
                label="Lösenord"
                value={password}
                onChange={setPassword}
                disabled={submitting}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            {errorMessage && (
              <Box marginBottom={5}>
                <Alert severity="error">{getErrorMessage(errorMessage!)}</Alert>
              </Box>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to="/"
                variant="contained"
                color="default"
                disabled={submitting}
                fullWidth
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                fullWidth
              >
                Återställ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PageLayout>
  );
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
