import React, { useState, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import api from "@kvittoapp/api-client/src";
import * as authActions from "@kvittoapp/ducks/src/auth";
import { User } from "@kvittoapp/ducks/src/auth/models";
import { TextField, Button } from "@material-ui/core";
import { PageLayout, PasswordField } from "../../../components";
import { Alert } from "@material-ui/lab";
import styles from "./Login.module.css";
import { getErrorMessage } from "../../../utils";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import { State } from "../../../store";

const mapStateToProps = (state: State) => ({
  activeCompany: selectActiveCompany(state),
});

const mapDispatchToProps = {
  add: (user: User) => authActions.add(user),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface LoginProps extends PropsFromRedux {
  initialEmail?: string | null;
  redirectPath: string;
}

const Login = ({ add, redirectPath, initialEmail }: LoginProps) => {
  const [email, setEmail] = useState(initialEmail || "");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginError(undefined);
    setEmail(e.target.value);
  };

  const onPasswordChange = (password: string) => {
    setLoginError(undefined);
    setPassword(password);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await api.createAuth(email, password);
      if (result.status === 200) {
        setLoggedIn(true);
        add(result.data);
      }
    } catch (e) {
      console.error(e);
      setLoginError("LOGIN_GENERIC");
    }
  };

  if (loggedIn) {
    navigate(redirectPath);
  }
  return (
    <PageLayout title="Logga in">
      <form onSubmit={onSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-postadress"
          name="email"
          autoComplete="email"
          type="email"
          autoFocus
          value={email}
          onChange={onEmailChange}
        />
        <PasswordField
          id="password"
          label="Lösenord"
          value={password}
          onChange={onPasswordChange}
          required
        />
        {loginError && (
          <div className={styles.errorMessage}>
            <Alert severity="error">{getErrorMessage(loginError)}</Alert>
          </div>
        )}
        <Button type="submit" fullWidth variant="contained" color="primary">
          Logga in
        </Button>
        <p>
          <Link to="/reset-password"> Har du glömt ditt lösenord? </Link>
        </p>
      </form>
    </PageLayout>
  );
};

export default connector(Login);
